














import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import ContactFormContactInfo from "@/components/contact-form/ContactFormContactInfo.vue";
import ContactFormAddressInfo from "@/components/contact-form/ContactFormAddressInfo.vue";
import ContactFormBusinessInfo from "@/components/contact-form/ContactFormBusinessInfo.vue";
import DialogMixin from "@/mixins/DialogMixin";
import BaseForm from "@/components/form/BaseForm";
import FileManagerService from "@/services/FileManagerService";

@Component({
  components: {
    FormCard,
    ContactFormContactInfo,
    ContactFormAddressInfo,
    ContactFormBusinessInfo,
  },
})
export default class ExpressionOfInterestDeleteDialog extends Mixins(
  DialogMixin,
  BaseForm
) {
  @Prop({ required: true, type: Number }) fileId!: number;

  isSaving = false;

  async submitForm(): Promise<void> {
    this.isSaving = true;
    await FileManagerService.deleteEoiFile(this.fileId);
    this.isSaving = false;
    this.$emit("success");
  }
}
