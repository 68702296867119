


































































import { Component, Mixins, Prop } from "vue-property-decorator";
import DialogMixin from "@/mixins/DialogMixin";
import FileManagerService from "@/services/FileManagerService";
import EoiDeleteDialog from "@/components/common/ExpressionOfInterestFileDeleteDialog.vue";
import BTFile from "@/entity/File";

@Component({
  components: {
    EoiDeleteDialog,
  },
})
export default class EoiFiles extends Mixins(DialogMixin) {
  @Prop({ required: true, type: Array }) files!: BTFile[];
  @Prop({ required: false, type: Number }) projectId!: number;
  @Prop({ required: false, type: Number }) buyerId!: number;
  @Prop({ required: false, type: Boolean, default: false })
  enableEditing!: boolean;

  newFiles: File[] = [];
  loading = false;

  selectedFileToDelete: BTFile | undefined;

  handleDeleteFileDialogOpen(file: BTFile) {
    this.selectedFileToDelete = file;
    this.openDialog("deleteDialog");
  }

  onDeleteSuccess() {
    this.$emit("refetch");
  }

  async uploadFiles() {
    if (this.projectId && this.buyerId) {
      this.loading = true;
      await Promise.all(
        this.newFiles.map((file) =>
          FileManagerService.uploadEoiFileManually(
            this.projectId,
            this.buyerId,
            file
          )
        )
      );
      this.loading = false;
      this.$emit("refetch");
    }
  }
}
